.ef-circle-divider {
  width: 0.5rem;
  height: 0.5rem;
  background-color: var(--primary-color);
  border-radius: 50%;
}

.p-tabview-panels {
  padding: 0 !important;
  margin-top: 2rem;
}