.container {
  display: flex;
  align-items: center;
  justify-content: flex-start;

}

.vehicles-details-card {
  transition: transform 0.3s ease-in-out, margin 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;

  /* Grundlegende Größe und weitere Stile */
}


.vehicles-details-card:hover{
  /* have it centered outstanding also with some distance to left and right */
  transform: scale(1.3);
  margin: 4rem 4rem 4rem 4rem;

  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out, margin 0.3s ease-in-out;

}

.vehicles-details-card-selected {
  /* have it centered outstanding also with some distance to left and right */
  transform: scale(1.3);
  margin: 4rem 4rem 4rem 4rem;

  /* box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.1); */

  /* Smooth transition for scale and box-shadow */
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out, margin 0.3s ease-in-out;
}

